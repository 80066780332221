/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { NgModule } from '@angular/core';
import { ScopeSelectorComponent } from './dso-selector/static-page-selector/scope-selector/scope-selector.component';
import {
  StaticPageSelectorComponent
} from './dso-selector/static-page-selector/static-page-selector/static-page-selector.component';
import {
  CreateStaticPageParentSelectorComponent
} from './dso-selector/modal-wrappers/static-page-wrapper/create-static-page-parent-selector/create-static-page-parent-selector.component';
import {
  EditStaticPageParentSelectorComponent
} from './dso-selector/modal-wrappers/static-page-wrapper/edit-static-page-wrapper/edit-static-page-parent-selector/edit-static-page-parent-selector.component';
import {
  EditStaticPageSelectorComponent
} from './dso-selector/modal-wrappers/static-page-wrapper/edit-static-page-wrapper/edit-static-page-selector/edit-static-page-selector.component';
import {
  EditStaticPageWrapperComponent
} from './dso-selector/modal-wrappers/static-page-wrapper/edit-static-page-wrapper/edit-static-page-wrapper.component';
import { DelayedActionButtonComponent } from './delayed-action/delayed-action-button/delayed-action-button.component';
import { DelayedActionService } from './delayed-action/delayed-action.service';
import { LanguageValidator } from './utils/language-validator.directive';
import { MissingTranslationHandler, TranslateModule } from '@ngx-translate/core';
import { MissingTranslationHelper } from '../../app/shared/translate/missing-translation.helper';
import { SharedModule } from '../../app/shared/shared.module';
import { AtmireAbstractTrackableComponent } from '../trackable/atmire-abstract-trackable.component';
import { CommonModule } from '@angular/common';
import { AbstractObjectUpdateComponent } from './object-update/abstract-object-update/abstract-object-update.component';
import { ObjectMetadataComponent } from './object-update/object-metadata/object-metadata.component';
import { EditDSOPageComponent } from './edit-dso-page/edit-dso-page.component';
import { AtmireObjectUpdatesService } from '../core/data/object-updates/atmire-object-updates.service';
import { ComColOrderService } from '../core/data/com-col-order-service';
import {
  AbstractItemUpdateComponent
} from '../../app/shared/object-update/abstract-item-update/abstract-item-update.component';
import { DYNAMIC_FORM_CONTROL_MAP_FN, DynamicFormsCoreModule } from '@ng-dynamic-forms/core';
import {
  dsDynamicFormControlMapFn
} from '../../app/shared/form/builder/ds-dynamic-form-ui/ds-dynamic-form-control-container.component';
import { OrderSwitchComponent } from './pagination-drag-and-drop/order-switch/order-switch.component';
import { DynamicFormsNGBootstrapUIModule } from '@ng-dynamic-forms/ui-ng-bootstrap';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {
  PaginatedDragAndDropItemParentMetadataListComponent
} from './pagination-drag-and-drop/paginated-drag-and-drop-item-parent-metadata-list/paginated-drag-and-drop-item-parent-metadata-list.component';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { DsPercentPipe } from './utils/ds-percent.pipe';
import { DsDecimalPipe } from './utils/ds-decimal-pipe';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { AtmireAlertComponent } from './alert/atmire-alert.component';
import { CollapsibleCardComponent } from './collapsible-card/collapsible-card.component';
import { DateRangePickerComponent } from './date-range-picker/date-range-picker.component';
import { ExportAsImageTriggerDirective } from './utils/export-as-image/export-as-image-trigger/export-as-image-trigger.directive';
import { InputFocusDirective } from './utils/input-autofocus.directive';
import { ExportAsImageModalComponent } from './utils/export-as-image/export-as-image-modal/export-as-image-modal.component';
import { ExportAsImageDomDirective } from './utils/export-as-image/export-as-image-dom/export-as-image-dom.directive';
import { ExportAsImageDirective } from './utils/export-as-image/export-as-image.directive';
import { MultiFacetValueSelectorComponent } from './facet-value-selector/multi-facet-value-selector/multi-facet-value-selector.component';
import { MultiDsoSelectorInputComponent } from './dso-selector/multi-dso-selector-input/multi-dso-selector-input.component';
import { AtmirePageWithSidebarComponent } from './sidebar/atmire-page-with-sidebar.component';
import { DsoSelectorInputComponent } from './dso-selector/dso-selector-input/dso-selector-input.component';
import { StatisticsMenuService } from './menu/statistics-menu/statistics-menu.service';
import { DsoSelectorService } from './dso-selector/services/dso-selector.service';
import { AtmireSidebarService } from './sidebar/atmire-sidebar.service';
import { StatletDataService } from '../atmire-cua/statlets/shared/data/services/statlet-data.service';
import { NgSelectModule } from '@ng-select/ng-select';
import {
  SearchResultSingleStatletComponent
} from '../atmire-cua/statlets/shared/list-view/search-result-single-statlet.component';
import {
  SearchResultStatletComponent
} from '../atmire-cua/statlets/shared/list-view/search-result-statlet.component';
import { DSpaceObjectOptionListElementComponent } from './dso-selector/dspace-object-option-list-element/dspace-object-option-list-element.component';

const MODULES = [
  // Do NOT include UniversalModule, HttpModule, or JsonpModule here
  CommonModule,
  SharedModule,
  DynamicFormsCoreModule,
  DynamicFormsNGBootstrapUIModule,
  NgbModule,
  BsDatepickerModule,
  NgSelectModule,
];

const PIPES = [
  // put shared pipes here
  DsPercentPipe,
  DsDecimalPipe,
];

const ENTRY_COMPONENTS = [
  DSpaceObjectOptionListElementComponent,
];

const COMPONENTS = [
  ...ENTRY_COMPONENTS,
  // put shared components here
  ScopeSelectorComponent,
  StaticPageSelectorComponent,
  CreateStaticPageParentSelectorComponent,
  EditStaticPageParentSelectorComponent,
  EditStaticPageSelectorComponent,
  EditStaticPageWrapperComponent,
  DelayedActionButtonComponent,
  AtmireAbstractTrackableComponent,
  AbstractObjectUpdateComponent,
  ObjectMetadataComponent,
  EditDSOPageComponent,
  OrderSwitchComponent,
  PaginatedDragAndDropItemParentMetadataListComponent,
  AtmireAlertComponent,
  AtmirePageWithSidebarComponent,
  CollapsibleCardComponent,
  DsoSelectorInputComponent,
  MultiDsoSelectorInputComponent,
  DateRangePickerComponent,
  MultiFacetValueSelectorComponent,
  ExportAsImageDirective,
  ExportAsImageTriggerDirective,
  ExportAsImageDomDirective,
  ExportAsImageModalComponent,
  InputFocusDirective,
  SearchResultStatletComponent,
  SearchResultSingleStatletComponent,
];

const PROVIDERS = [
  DelayedActionService,
  AbstractObjectUpdateComponent,
  AbstractItemUpdateComponent,
  {
    provide: DYNAMIC_FORM_CONTROL_MAP_FN,
    useValue: dsDynamicFormControlMapFn
  },
  ObjectMetadataComponent,
  EditDSOPageComponent,
  AtmireObjectUpdatesService,
  ComColOrderService,
  StatisticsMenuService,
  StatletDataService,
  AtmireSidebarService,
  DsoSelectorService,
];

const DIRECTIVES = [
  LanguageValidator
];

@NgModule({
  imports: [
    ...MODULES,
    TranslateModule.forChild({
      missingTranslationHandler: { provide: MissingTranslationHandler, useClass: MissingTranslationHelper },
      useDefaultLang: true
    }),
    TooltipModule.forRoot(),
    CollapseModule.forRoot(),
    BsDatepickerModule.forRoot(),
    BsDropdownModule.forRoot(),
  ],
  declarations: [
    ...PIPES,
    ...COMPONENTS,
    ...DIRECTIVES,
  ],
  providers: [
    ...PROVIDERS
  ],
  exports: [
    ...MODULES,
    ...PIPES,
    ...COMPONENTS,
    ...DIRECTIVES,
  ]
})

/**
 * A module for shared components/services between other atmire modules
 * Import this module in an atmire module to get access to its contents
 */
export class AtmireSharedModule {
}
