<!--
  The contents of this file are subject to the license and copyright
  detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
  tree and available online at

  https://www.atmire.com/software-license/
 -->
<div class="d-flex flex-row">
  <ds-themed-item-page-title-field [item]="object" class="mr-auto">
  </ds-themed-item-page-title-field>
  <div class="d-flex pl-2">
    <ds-atmire-saved-item-list-control class="mr-1" [item]="object"></ds-atmire-saved-item-list-control>
    <ds-atmire-item-export-dropdown class="mr-1" [item]="object" [compact]="true"></ds-atmire-item-export-dropdown>
    <ds-dso-add-uuid-button class="mr-1" [uuid]="object.uuid" [msgPrefix]="'item.page.'">
    </ds-dso-add-uuid-button>
    <ds-dso-edit-menu></ds-dso-edit-menu>
  </div>
</div>
<div class="row mb-4">
  <div class="col-xs-12 col-md-4">
    <ds-metadata-field-wrapper [hideIfNoTextContent]="false">
      <ds-themed-thumbnail [thumbnail]="object?.thumbnail | async"></ds-themed-thumbnail>
    </ds-metadata-field-wrapper>
    <ds-themed-generic-item-page-field class="item-page-fields" [item]="object"
                                       [fields]="['creativeworkseries.issn']"
                                       [label]="'journal.page.issn'">
    </ds-themed-generic-item-page-field>
    <ds-themed-generic-item-page-field class="item-page-fields" [item]="object"
                                       [fields]="['creativework.publisher']"
                                       [label]="'journal.page.publisher'">
    </ds-themed-generic-item-page-field>
    <ds-themed-generic-item-page-field [item]="object"
                                       [fields]="['creativework.editor']"
                                       [label]="'journal.page.editor'">
    </ds-themed-generic-item-page-field>
  </div>
  <div class="col-xs-12 col-md-6">
    <ds-related-items
      [parentItem]="object"
      [relationType]="'isVolumeOfJournal'"
      [label]="'relationships.isVolumeOf' | translate">
    </ds-related-items>
    <ds-themed-generic-item-page-field class="item-page-fields" [item]="object"
                                       [fields]="['dc.description']"
                                       [label]="'journal.page.description'">
    </ds-themed-generic-item-page-field>
    <div>
      <a class="btn btn-outline-primary" [routerLink]="[itemPageRoute + '/full']">
        {{"item.page.link.full" | translate}}
      </a>
    </div>
  </div>
  <div class="mt-5 w-100">
    <ds-tabbed-related-entities-search  [item]="object"
                                        [relationTypes]="[{
                                          label: 'isJournalOfPublication',
                                          filter: 'isJournalOfPublication'
                                        }]">
    </ds-tabbed-related-entities-search>
  </div>
</div>
<ds-atmire-cua-statlets-section [dso]="object"></ds-atmire-cua-statlets-section>
