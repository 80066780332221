<!--
  The contents of this file are subject to the license and copyright
  detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
  tree and available online at

  https://www.atmire.com/software-license/
 -->
<div class="vertical-bar-chart" #wrapper>
  <ds-ngx-custom-bar-vertical-inline *ngIf="normalizedData$ | async"
                                       [results]="normalizedData$ | async"
                                       [view]="[wrapper.offsetWidth === 0 ? view[0] : wrapper.offsetWidth, wrapper.offsetHeight === 0 ? view[1] : wrapper.offsetHeight]"
                                       [scheme]="colors$ | async"
                                       [barPadding]="2"
                                       [animations]="false"
  ></ds-ngx-custom-bar-vertical-inline>
</div>
