<!--
  The contents of this file are subject to the license and copyright
  detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
  tree and available online at

  https://www.atmire.com/software-license/
 -->
<div class="d-flex flex-row">
  <ds-themed-item-page-title-field class="mr-auto" [item]="object">
  </ds-themed-item-page-title-field>
  <div class="d-flex pl-2">
    <ds-atmire-saved-item-list-control class="mr-1" [item]="object"></ds-atmire-saved-item-list-control>
    <ds-atmire-item-export-dropdown class="mr-1" [item]="object" [compact]="true"></ds-atmire-item-export-dropdown>
    <ds-dso-add-uuid-button class="mr-1" [uuid]="object.uuid" [msgPrefix]="'item.page.'">
    </ds-dso-add-uuid-button>
    <ds-dso-edit-menu></ds-dso-edit-menu>
  </div>
</div>
<div class="row mb-4">
  <div class="col-xs-12 col-md-4">
    <ds-metadata-field-wrapper [hideIfNoTextContent]="false">
      <ds-themed-thumbnail [thumbnail]="object?.thumbnail | async"
                           [defaultImage]="'assets/images/person-placeholder.svg'"
                           [alt]="'thumbnail.person.alt'"
                           [placeholder]="'thumbnail.person.placeholder'">
      </ds-themed-thumbnail>
    </ds-metadata-field-wrapper>
    <ds-themed-generic-item-page-field [item]="object"
                                       [fields]="['person.email']"
                                       [label]="'person.page.email'">
    </ds-themed-generic-item-page-field>
    <!--<ds-themed-generic-item-page-field [item]="object"-->
    <!--[fields]="['person.identifier.orcid']"-->
    <!--[label]="'person.page.orcid'">-->
    <!--</ds-themed-generic-item-page-field>-->
    <ds-themed-generic-item-page-field [item]="object"
                                       [fields]="['person.birthDate']"
                                       [label]="'person.page.birthdate'">
    </ds-themed-generic-item-page-field>
    <!--<ds-themed-generic-item-page-field [item]="object"-->
    <!--[fields]="['person.identifier.staffid']"-->
    <!--[label]="'person.page.staffid'">-->
    <!--</ds-themed-generic-item-page-field>-->
  </div>
  <div class="col-xs-12 col-md-6">
    <ds-related-items
      [parentItem]="object"
      [relationType]="'isProjectOfPerson'"
      [label]="'relationships.isProjectOf' | translate">
    </ds-related-items>
    <ds-related-items
      [parentItem]="object"
      [relationType]="'isOrgUnitOfPerson'"
      [label]="'relationships.isOrgUnitOf' | translate">
    </ds-related-items>
    <ds-themed-generic-item-page-field [item]="object"
                                       [fields]="['person.jobTitle']"
                                       [label]="'person.page.jobtitle'">
    </ds-themed-generic-item-page-field>
    <ds-themed-generic-item-page-field [item]="object"
                                       [fields]="['person.familyName']"
                                       [label]="'person.page.lastname'">
    </ds-themed-generic-item-page-field>
    <ds-themed-generic-item-page-field [item]="object"
                                       [fields]="['person.givenName']"
                                       [label]="'person.page.firstname'">
    </ds-themed-generic-item-page-field>
    <div>
      <a class="btn btn-outline-primary" [routerLink]="[itemPageRoute + '/full']">
        {{"item.page.link.full" | translate}}
      </a>
    </div>
  </div>
  <div class="mt-5 w-100">
    <ds-tabbed-related-entities-search  [item]="object"
                                        [relationTypes]="[{
                                          label: 'isAuthorOfPublication',
                                          filter: 'isAuthorOfPublication'
                                        }]">
    </ds-tabbed-related-entities-search>
  </div>
</div>
<ds-atmire-cua-statlets-section [dso]="object"></ds-atmire-cua-statlets-section>
