/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../app/shared/shared.module';
import { SearchModule } from '../../app/shared/search/search.module';
import { FormsModule } from '@angular/forms';
import { RootModule } from '../../app/root.module';
import { NavbarModule } from '../../app/navbar/navbar.module';
import { ItemPageModule } from '../../app/item-page/item-page.module';
import { PublicationComponent } from './app/item-page/simple/item-types/publication/publication.component';
import { UntypedItemComponent } from './app/item-page/simple/item-types/untyped-item/untyped-item.component';
import { SocialSharingModule } from '../../app-atmire/social-sharing/social-sharing.module';
import { BrowseByDatePageComponent } from './app/browse-by/browse-by-date-page/browse-by-date-page.component';
import { BrowseByMetadataPageComponent } from './app/browse-by/browse-by-metadata-page/browse-by-metadata-page.component';
import { BrowseByTitlePageComponent } from './app/browse-by/browse-by-title-page/browse-by-title-page.component';
import { JournalSearchResultGridElementComponent } from './app/entity-groups/journal-entities/item-grid-elements/search-result-grid-elements/journal/journal-search-result-grid-element.component';
import { JournalVolumeSearchResultListElementComponent } from './app/entity-groups/journal-entities/item-list-elements/search-result-list-elements/journal-volume/journal-volume-search-result-list-element.component';
import { ProjectSearchResultGridElementComponent } from './app/entity-groups/research-entities/item-grid-elements/search-result-grid-elements/project/project-search-result-grid-element.component';
import { JournalIssueSearchResultGridElementComponent } from './app/entity-groups/journal-entities/item-grid-elements/search-result-grid-elements/journal-issue/journal-issue-search-result-grid-element.component';
import { ProjectComponent } from './app/entity-groups/research-entities/item-pages/project/project.component';
import { PersonComponent } from './app/entity-groups/research-entities/item-pages/person/person.component';
import { OrgUnitComponent } from './app/entity-groups/research-entities/item-pages/org-unit/org-unit.component';
import { OrgUnitSearchResultListElementComponent } from './app/entity-groups/research-entities/item-list-elements/search-result-list-elements/org-unit/org-unit-search-result-list-element.component';
import { ProjectSearchResultListElementComponent } from './app/entity-groups/research-entities/item-list-elements/search-result-list-elements/project/project-search-result-list-element.component';
import { PersonSearchResultListElementComponent } from './app/entity-groups/research-entities/item-list-elements/search-result-list-elements/person/person-search-result-list-element.component';
import { PersonSearchResultGridElementComponent } from './app/entity-groups/research-entities/item-grid-elements/search-result-grid-elements/person/person-search-result-grid-element.component';
import { OrgUnitSearchResultGridElementComponent } from './app/entity-groups/research-entities/item-grid-elements/search-result-grid-elements/org-unit/org-unit-search-result-grid-element.component';
import { JournalVolumeComponent } from './app/entity-groups/journal-entities/item-pages/journal-volume/journal-volume.component';
import { JournalIssueComponent } from './app/entity-groups/journal-entities/item-pages/journal-issue/journal-issue.component';
import { JournalComponent } from './app/entity-groups/journal-entities/item-pages/journal/journal.component';
import { JournalIssueSearchResultListElementComponent } from './app/entity-groups/journal-entities/item-list-elements/search-result-list-elements/journal-issue/journal-issue-search-result-list-element.component';
import { JournalSearchResultListElementComponent } from './app/entity-groups/journal-entities/item-list-elements/search-result-list-elements/journal/journal-search-result-list-element.component';
import { JournalVolumeSearchResultGridElementComponent } from './app/entity-groups/journal-entities/item-grid-elements/search-result-grid-elements/journal-volume/journal-volume-search-result-grid-element.component';
import { ClaimedSearchResultListElementComponent } from './app/shared/object-list/my-dspace-result-list-element/claimed-search-result/claimed-search-result-list-element.component';
import { WorkflowItemSearchResultListElementComponent } from './app/shared/object-list/my-dspace-result-list-element/workflow-item-search-result/workflow-item-search-result-list-element.component';
import { PoolSearchResultListElementComponent } from './app/shared/object-list/my-dspace-result-list-element/pool-search-result/pool-search-result-list-element.component';
import { ItemSearchResultGridElementComponent } from './app/shared/object-grid/search-result-grid-element/item-search-result/item/item-search-result-grid-element.component';
import { AtmireFeaturedItemsSharedModule } from '../../app-atmire/atmire-featured-items/atmire-featured-items-shared.module';
import { WorkspaceItemSearchResultListElementComponent } from './app/shared/object-list/my-dspace-result-list-element/workspace-item-search-result/workspace-item-search-result-list-element.component';
import { ClaimedTaskSearchResultDetailElementComponent } from './app/shared/object-detail/my-dspace-result-detail-element/claimed-task-search-result/claimed-task-search-result-detail-element.component';
import { ItemSearchResultListElementComponent } from './app/shared/object-list/search-result-list-element/item-search-result/item-types/item/item-search-result-list-element.component';
import { ComcolModule } from '../../app/shared/comcol/comcol.module';
import { AtmireSelectiveExportSharedModule } from '../../app-atmire/atmire-selective-export/atmire-selective-export-shared/atmire-selective-export-shared.module';
import { ItemSharedModule } from '../../app/item-page/item-shared.module';
import { ItemListPreviewComponent } from './app/shared/object-list/my-dspace-result-list-element/item-list-preview/item-list-preview.component';
import { HomeNewsComponent } from './app/home-page/home-news/home-news.component';
import { HomePageModule } from '../../app/home-page/home-page.module';
import { AtmireAppModule } from '../../app-atmire/atmire-app.module';
import { CollectionGridElementComponent } from './app/shared/object-grid/collection-grid-element/collection-grid-element.component';
import { CollectionListElementComponent } from './app/shared/object-list/collection-list-element/collection-list-element.component';
import { CollectionSearchResultGridElementComponent } from './app/shared/object-grid/search-result-grid-element/collection-search-result/collection-search-result-grid-element.component';
import { CommunityGridElementComponent } from './app/shared/object-grid/community-grid-element/community-grid-element.component';
import { CommunityListElementComponent } from './app/shared/object-list/community-list-element/community-list-element.component';
import { CommunitySearchResultGridElementComponent } from './app/shared/object-grid/search-result-grid-element/community-search-result/community-search-result-grid-element.component';
import { AdminSearchModule } from '../../app/admin/admin-search-page/admin-search.module';
import { ResearchEntitiesModule } from '../../app/entity-groups/research-entities/research-entities.module';
import { AdminWorkflowModuleModule } from '../../app/admin/admin-workflow-page/admin-workflow.module';
import {
  CollectionSidebarSearchListElementComponent
} from './app/shared/object-list/sidebar-search-list-element/collection/collection-sidebar-search-list-element.component';
import {
  CommunitySidebarSearchListElementComponent
} from './app/shared/object-list/sidebar-search-list-element/community/community-sidebar-search-list-element.component';
import { CollectionFormModule } from '../../app/collection-page/collection-form/collection-form.module';
import { CommunityFormModule } from '../../app/community-page/community-form/community-form.module';
import {
  CreateCollectionPageComponent
} from './app/collection-page/create-collection-page/create-collection-page.component';
import {
  CreateCommunityPageComponent
} from './app/community-page/create-community-page/create-community-page.component';
import {
  AtmireRelatedItemsModule
} from '../../app-atmire/atmire-related-items/atmire-related-items.module';
import { AtmireSavedItemListOtherModule } from '../../app-atmire/atmire-saved-item-list/atmire-saved-item-list-other/atmire-saved-item-list-other.module';
import { SharedStatletsModule } from '../../app-atmire/atmire-cua/statlets/shared/shared-statlets.module';
import { AtmireSharedModule } from '../../app-atmire/shared/atmire-shared.module';
import {
  CollectionSearchResultListElementComponent
} from './app/object-list/search-result-list-element/collection-search-result/collection-search-result-list-element.component';
import {
  CommunitySearchResultListElementComponent
} from './app/object-list/search-result-list-element/community-search-result/community-search-result-list-element.component';
import { HeaderComponent } from './app/header/header.component';

/**
 * Add components that use a custom decorator to ENTRY_COMPONENTS as well as DECLARATIONS.
 * This will ensure that decorator gets picked up when the app loads
 */
const ENTRY_COMPONENTS = [
  PublicationComponent,
  UntypedItemComponent,
  BrowseByDatePageComponent,
  BrowseByMetadataPageComponent,
  BrowseByTitlePageComponent,
  JournalSearchResultGridElementComponent,
  JournalIssueSearchResultGridElementComponent,
  JournalVolumeSearchResultGridElementComponent,
  JournalSearchResultListElementComponent,
  JournalIssueSearchResultListElementComponent,
  JournalVolumeSearchResultListElementComponent,
  JournalComponent,
  JournalIssueComponent,
  JournalVolumeComponent,
  OrgUnitSearchResultGridElementComponent,
  PersonSearchResultGridElementComponent,
  ProjectSearchResultGridElementComponent,
  OrgUnitSearchResultListElementComponent,
  PersonSearchResultListElementComponent,
  ProjectSearchResultListElementComponent,
  OrgUnitComponent,
  PersonComponent,
  ProjectComponent,
  PublicationComponent,
  UntypedItemComponent,
  ItemSearchResultGridElementComponent,
  ClaimedSearchResultListElementComponent,
  PoolSearchResultListElementComponent,
  WorkflowItemSearchResultListElementComponent,
  WorkspaceItemSearchResultListElementComponent,
  ClaimedTaskSearchResultDetailElementComponent,
  ItemSearchResultListElementComponent,
  CollectionGridElementComponent,
  CollectionListElementComponent,
  CollectionSearchResultGridElementComponent,
  CollectionSidebarSearchListElementComponent,
  CommunityGridElementComponent,
  CommunityListElementComponent,
  CommunitySearchResultGridElementComponent,
  CommunitySidebarSearchListElementComponent,
  PoolSearchResultListElementComponent,
  ItemListPreviewComponent,
  CollectionSearchResultListElementComponent,
  CommunitySearchResultListElementComponent,
];

const DECLARATIONS = [
  ...ENTRY_COMPONENTS,
  HomeNewsComponent,
  ItemListPreviewComponent,
  CreateCollectionPageComponent,
  CreateCommunityPageComponent,
  HeaderComponent,
];

@NgModule({
  imports: [
    AdminSearchModule,
    AdminWorkflowModuleModule,
    AtmireAppModule,
    CommonModule,
    ComcolModule,
    CollectionFormModule,
    CommunityFormModule,
    ResearchEntitiesModule,
    SharedModule,
    SearchModule,
    FormsModule,
    RootModule,
    NavbarModule,
    ItemPageModule,
    ComcolModule,
    AtmireSelectiveExportSharedModule,
    ItemSharedModule,
    HomePageModule,
    AtmireAppModule,
    ItemSharedModule,
    HomePageModule,
    AtmireFeaturedItemsSharedModule,
    SocialSharingModule,
    AtmireRelatedItemsModule,
    AtmireSavedItemListOtherModule,
    SharedStatletsModule,
    AtmireSharedModule,
  ],
  declarations: DECLARATIONS,
  providers: [
    ...ENTRY_COMPONENTS.map((component) => ({ provide: component }))
  ],
})
/**
 * This module is included in the main bundle that gets downloaded at first page load. So it should
 * contain only the themed components that have to be available immediately for the first page load,
 * and the minimal set of imports required to make them work. Anything you can cut from it will make
 * the initial page load faster, but may cause the page to flicker as components that were already
 * rendered server side need to be lazy-loaded again client side
 *
 * Themed EntryComponents should also be added here
 */
export class EagerThemeModule {
}
