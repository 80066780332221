/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AtmireItemPageRoutingModule } from './atmire-item-page-routing.module';

const ENTRY_COMPONENTS = [
  // put only entry components that use custom decorator
];

const DECLARATIONS = [
];

@NgModule({
  imports: [
    CommonModule,
    AtmireItemPageRoutingModule
  ],
  declarations: [
    ...DECLARATIONS,
  ],
  exports: [
    ...DECLARATIONS
  ]
})
export class AtmireItemPageModule {

  /**
   * NOTE: this method allows to resolve issue with components that using a custom decorator
   * which are not loaded during SSR otherwise
   */
  static withEntryComponents() {
    return {
      ngModule: AtmireItemPageModule,
      providers: ENTRY_COMPONENTS.map((component) => ({ provide: component }))
    };
  }

}
