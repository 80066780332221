/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Component } from '@angular/core';
import {
  CollectionSearchResult
} from '../../../../../app/shared/object-collection/shared/collection-search-result.model';
import {
  listableObjectComponent
} from '../../../../../app/shared/object-collection/shared/listable-object/listable-object.decorator';
import { Context } from '../../../../../app/core/shared/context.model';
import { ViewMode } from '../../../../../app/core/shared/view-mode.model';
import {
  CollectionSidebarSearchListElementComponent as AtmireBaseComponent
} from '../../../../../themes/atmire/app/shared/object-list/sidebar-search-list-element/collection/collection-sidebar-search-list-element.component';

@listableObjectComponent(CollectionSearchResult, ViewMode.ListElement, Context.ScopeSelectorModal, 'client')
@listableObjectComponent(CollectionSearchResult, ViewMode.ListElement, Context.ScopeSelectorModalCurrent, 'client')
@Component({
  selector: 'ds-collection-scope-search-list-element',
  templateUrl: './collection-scope-search-list-element.component.html',
  // templateUrl: '../../../../../app/shared/object-list/sidebar-search-list-element/sidebar-search-list-element.component.html',
})
export class CollectionScopeSearchListElementComponent extends AtmireBaseComponent {
}
