<!--
  The contents of this file are subject to the license and copyright
  detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
  tree and available online at

  https://www.atmire.com/software-license/
 -->
<div class="d-flex flex-row">
  <ds-themed-item-page-title-field [item]="object" class="mr-auto">
  </ds-themed-item-page-title-field>
  <div class="d-flex pl-2">
    <ds-atmire-saved-item-list-control class="mr-1" [item]="object"></ds-atmire-saved-item-list-control>
    <ds-atmire-item-export-dropdown class="mr-1" [item]="object" [compact]="true"></ds-atmire-item-export-dropdown>
    <ds-dso-add-uuid-button class="mr-1" [uuid]="object.uuid" [msgPrefix]="'item.page.'">
    </ds-dso-add-uuid-button>
    <ds-dso-edit-menu></ds-dso-edit-menu>
  </div>
</div>
<div class="row mb-4">
  <div class="col-xs-12 col-md-4">
    <ds-metadata-field-wrapper [hideIfNoTextContent]="false">
      <ds-themed-thumbnail
        [thumbnail]="object?.thumbnail | async"
        [defaultImage]="'assets/images/project-placeholder.svg'"
        [alt]="'thumbnail.project.alt'"
        [placeholder]="'thumbnail.project.placeholder'">
      </ds-themed-thumbnail>
    </ds-metadata-field-wrapper>
    <!--<ds-themed-generic-item-page-field [item]="object"-->
    <!--[fields]="['project.identifier.status']"-->
    <!--[label]="'project.page.status'">-->
    <!--</ds-themed-generic-item-page-field>-->
    <ds-themed-metadata-representation-list
      [parentItem]="object"
      [itemType]="'OrgUnit'"
      [metadataFields]="['project.contributor.other']"
      [label]="'project.page.contributor' | translate">
    </ds-themed-metadata-representation-list>
    <ds-themed-generic-item-page-field [item]="object"
                                       [fields]="['project.identifier.funder']"
                                       [label]="'project.page.funder'">
    </ds-themed-generic-item-page-field>
    <ds-themed-generic-item-page-field [item]="object"
                                       [fields]="['dc.identifier']"
                                       [label]="'project.page.id'">
    </ds-themed-generic-item-page-field>
    <!--<ds-themed-generic-item-page-field [item]="object"-->
    <!--[fields]="['project.identifier.expectedcompletion']"-->
    <!--[label]="'project.page.expectedcompletion'">-->
    <!--</ds-themed-generic-item-page-field>-->
  </div>
  <div class="col-xs-12 col-md-6">
    <ds-related-items
      [parentItem]="object"
      [relationType]="'isPersonOfProject'"
      [label]="'relationships.isPersonOf' | translate">
    </ds-related-items>
    <ds-related-items
      [parentItem]="object"
      [relationType]="'isPublicationOfProject'"
      [label]="'relationships.isPublicationOf' | translate">
    </ds-related-items>
    <ds-related-items
      [parentItem]="object"
      [relationType]="'isOrgUnitOfProject'"
      [label]="'relationships.isOrgUnitOf' | translate">
    </ds-related-items>
    <ds-themed-generic-item-page-field [item]="object"
                                       [fields]="['dc.description']"
                                       [label]="'project.page.description'">
    </ds-themed-generic-item-page-field>
    <ds-themed-generic-item-page-field [item]="object"
                                       [fields]="['dc.subject']"
                                       [label]="'project.page.keyword'">
    </ds-themed-generic-item-page-field>
    <div>
      <a class="btn btn-outline-primary" [routerLink]="[itemPageRoute + '/full']">
        {{"item.page.link.full" | translate}}
      </a>
    </div>
  </div>
</div>
<ds-atmire-cua-statlets-section [dso]="object"></ds-atmire-cua-statlets-section>
