/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { NgModule } from '@angular/core';
import { MissingTranslationHandler, TranslateModule } from '@ngx-translate/core';
import { MissingTranslationHelper } from '../../app/shared/translate/missing-translation.helper';
import {
  ClientFileSectionComponent
} from '../item-page/simple/field-components/file-section/client-file-section.component';
import { SharedModule } from '../../app/shared/shared.module';
import { CommonModule } from '@angular/common';
import { AltmetricDonutComponent } from './citation/altmetric-donut/altmetric-donut.component';
import {
  AtmireObjectCollectionModule
} from '../../app-atmire/atmire-object-collection/atmire-object-collection.module';

const MODULES = [
  // Do NOT include UniversalModule, HttpModule, or JsonpModule here
];

const ROOT_MODULES = [
  TranslateModule.forChild({
    missingTranslationHandler: { provide: MissingTranslationHandler, useClass: MissingTranslationHelper },
    useDefaultLang: true
  })
];

const PIPES = [
  // put shared pipes here
];

const COMPONENTS = [
  // put shared components here
  AltmetricDonutComponent,
  ClientFileSectionComponent,
];

const ENTRY_COMPONENTS = [
  // put only entry components that use custom decorator
];

const SHARED_ITEM_PAGE_COMPONENTS = [];

const PROVIDERS = [];

const DIRECTIVES = [];

@NgModule({
  imports: [
    ...MODULES,
    ...ROOT_MODULES,
    CommonModule,
    SharedModule,
    AtmireObjectCollectionModule,
  ],
  declarations: [
    ...PIPES,
    ...COMPONENTS,
    ...DIRECTIVES,
    ...SHARED_ITEM_PAGE_COMPONENTS,
    ...ENTRY_COMPONENTS,
  ],
  providers: [
    ...PROVIDERS
  ],
  exports: [
    ...MODULES,
    ...PIPES,
    ...COMPONENTS,
    ...SHARED_ITEM_PAGE_COMPONENTS,
    ...DIRECTIVES,
    TranslateModule,
  ]
})

/**
 * This module handles all components and pipes that need to be shared among multiple other modules
 */
export class ClientSharedModule {
  /**
   * NOTE: this method allows to resolve issue with components that using a custom decorator
   * which are not loaded during SSR otherwise
   */
  static withEntryComponents() {
    return {
      ngModule: ClientSharedModule,
      providers: ENTRY_COMPONENTS.map((component) => ({ provide: component }))
    };
  }
}
