<!--
  The contents of this file are subject to the license and copyright
  detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
  tree and available online at

  https://www.atmire.com/software-license/
 -->
<div class="d-flex flex-row">
  <ds-themed-item-page-title-field [item]="object" class="mr-auto">
  </ds-themed-item-page-title-field>
  <div class="d-flex pl-2">
    <ds-atmire-saved-item-list-control class="mr-1" [item]="object"></ds-atmire-saved-item-list-control>
    <ds-atmire-item-export-dropdown class="mr-1" [item]="object" [compact]="true"></ds-atmire-item-export-dropdown>
    <ds-dso-add-uuid-button class="mr-1" [uuid]="object.uuid" [msgPrefix]="'item.page.'">
    </ds-dso-add-uuid-button>
    <ds-dso-edit-menu></ds-dso-edit-menu>
  </div>
</div>
<div class="row mb-4">
  <div class="col-xs-12 col-md-4">
    <ds-metadata-field-wrapper [hideIfNoTextContent]="false">
      <ds-themed-thumbnail [thumbnail]="object?.thumbnail | async"></ds-themed-thumbnail>
    </ds-metadata-field-wrapper>
    <ds-themed-generic-item-page-field [item]="object"
                                       [fields]="['publicationvolume.volumeNumber']"
                                       [label]="'journalvolume.page.volume'">
    </ds-themed-generic-item-page-field>
    <ds-themed-generic-item-page-field [item]="object"
                                       [fields]="['publicationissue.issueNumber']"
                                       [label]="'journalissue.page.number'">
    </ds-themed-generic-item-page-field>
    <ds-themed-generic-item-page-field [item]="object"
                                       [fields]="['creativework.datePublished']"
                                       [label]="'journalissue.page.issuedate'">
    </ds-themed-generic-item-page-field>
    <ds-themed-generic-item-page-field [item]="object"
                                       [fields]="['journal.title']"
                                       [label]="'journalissue.page.journal-title'">
    </ds-themed-generic-item-page-field>
    <ds-themed-generic-item-page-field [item]="object"
                                       [fields]="['creativeworkseries.issn']"
                                       [label]="'journalissue.page.journal-issn'">
    </ds-themed-generic-item-page-field>
  </div>
  <div class="col-xs-12 col-md-6">
    <ds-related-items
      [parentItem]="object"
      [relationType]="'isJournalVolumeOfIssue'"
      [label]="'relationships.isSingleVolumeOf' | translate">
    </ds-related-items>
    <ds-related-items
      class="mb-1 mt-1"
      [parentItem]="object"
      [relationType]="'isPublicationOfJournalIssue'"
      [label]="'relationships.isPublicationOfJournalIssue' | translate">
    </ds-related-items>
    <ds-themed-generic-item-page-field [item]="object"
                                       [fields]="['dc.description']"
                                       [label]="'journalissue.page.description'">
    </ds-themed-generic-item-page-field>
    <ds-themed-generic-item-page-field [item]="object"
                                       [fields]="['creativework.keywords']"
                                       [label]="'journalissue.page.keyword'">
    </ds-themed-generic-item-page-field>
    <div>
      <a class="btn btn-outline-primary" [routerLink]="[itemPageRoute + '/full']">
        {{"item.page.link.full" | translate}}
      </a>
    </div>
  </div>
</div>
<ds-atmire-cua-statlets-section [dso]="object"></ds-atmire-cua-statlets-section>
