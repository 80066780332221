<!--
  The contents of this file are subject to the license and copyright
  detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
  tree and available online at

  https://www.atmire.com/software-license/
 -->
<div class="row" *ngIf="iiifEnabled">
  <div class="col-12">
    <ds-mirador-viewer id="iiif-viewer"
                       [object]="object"
                       [searchable]="iiifSearchEnabled"
                       [query]="iiifQuery$ | async">
    </ds-mirador-viewer>
  </div>
</div>
  <div class="top-section-item-page py-5">
    <div class="container">
      <div class="row">
        <div class="col-12 col-md-3 col-lg-3 d-flex justify-content-center item-page-thumbnail-container">
          <div class="flex-column">
            <ng-container *ngIf="!mediaViewer.image">
              <ds-metadata-field-wrapper [hideIfNoTextContent]="false">
                <ds-themed-thumbnail [thumbnail]="object?.thumbnail | async"></ds-themed-thumbnail>
              </ds-metadata-field-wrapper>
            </ng-container>
            <ng-container *ngIf="mediaViewer.image">
              <ds-media-viewer [item]="object" [videoOptions]="mediaViewer.video"></ds-media-viewer>
            </ng-container>
<!--            <ds-cc-licence-icons></ds-cc-licence-icons>-->
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-6 d-flex flex-column">
          <div class="d-flex flex-row">
            <h2 [innerHTML]="object.firstMetadataValue('dc.title')"></h2>
            <div class="publication-actions-list d-flex flex-row">
              <ds-atmire-saved-item-list-control [item]="object"></ds-atmire-saved-item-list-control>
              <ds-atmire-item-export-dropdown [item]="object" [compact]="true"></ds-atmire-item-export-dropdown>
              <ds-dso-add-uuid-button [uuid]="object.uuid" [msgPrefix]="'item.page.'">
              </ds-dso-add-uuid-button>
              <ds-dso-edit-menu></ds-dso-edit-menu>
            </div>
          </div>
          <h3 class="font-weight-bold">{{ object.firstMetadataValue('dc.title.subtitle') }}</h3>
          <ds-sharing-buttons [fieldWrapper]="false" class="mb-2 mt-auto"
                              [shareTitle]="object?.firstMetadataValue(['dc.title'])"
                              [shareDescription]="object?.firstMetadataValue(['dc.description', 'dc.description.abstract'])"
                              [shareSubjects]="object?.allMetadataValues(['dc.subject'])"
                              [shareThumbnailURL]="(object?.thumbnail | async)?._links?.content"
                              [shareURL]="socialShareUrl"
          ></ds-sharing-buttons>
        </div>
        <div class="col-12 col-md-3 d-flex flex-column">
          <ds-client-item-page-file-section [item]="object"
                                            icon="fas fa-file mx-1"
                                            [text]="'item.page.download-file-type'">
          </ds-client-item-page-file-section>
        </div>
      </div>
    </div>
  </div>

  <div class="bottom-section-item-page py-5 justify-content-center">
    <div class="container">
      <div class="row">
        <div class="col-12 col-md-3 col-lg-2 d-flex flex-column">
          <ng-container *ngIf="object.firstMetadataValue('dc.date.issued') as yearOfPublication">
            <p class="metadata-field">
              <span>{{ 'item.page.year-of-publication' | translate }}</span>
              {{ parseYear(yearOfPublication) }}
            </p>
            <hr>
          </ng-container>
          <ng-container *ngIf="object.firstMetadataValue('fao.placeofpublication') as publicationPlace">
            <p class="metadata-field">
              <span>{{ 'item.page.place-of-publication' | translate }}</span>
              {{ publicationPlace }}
            </p>
            <hr>
          </ng-container>
          <ng-container *ngIf="object.firstMetadataValue('dc.format.numberofpages') as pages">
            <p class="metadata-field">
              <span>{{ 'item.page.pages' | translate }}</span>
              {{ pages }}
            </p>
            <hr>
          </ng-container>
          <ng-container *ngIf="object.firstMetadataValue('dc.contributor.author') as author">
            <p class="metadata-field">
              <span>{{ 'item.page.author' | translate }}</span>
              {{ author }}
            </p>
            <hr>
          </ng-container>
          <ng-container *ngIf="object.firstMetadataValue('dc.publisher') as publisher">
            <p class="metadata-field">
              <span>{{ 'item.page.publisher' | translate }}</span>
              {{ publisher }}
            </p>
            <hr>
          </ng-container>
          <ng-container *ngIf="object.firstMetadataValue('dc.type') as type">
            <p class="metadata-field">
              <span>{{ 'dc.type' | translate }}</span>
              {{ type }}
            </p>
            <hr>
          </ng-container>
          <ng-container *ngIf="object.firstMetadataValue('dc.identifier.isbn') as isbn">
            <p class="metadata-field">
              <span>{{ 'item.page.isbn' | translate }}</span>
              {{ isbn }}
            </p>
            <hr>
          </ng-container>
          <ng-container *ngIf="object.firstMetadataValue('dc.relation.ispartofseries') as seriesOf">
            <p class="metadata-field">
              <span>{{ 'dc.relation.ispartofseries' | translate }}</span>
              <a [queryParams]="{'f.isPartOfSeries': seriesOf + ',equals' , 'spc.sf': 'dc.date.issued','spc.sd': 'DESC' }" [routerLink]="'/search'">{{ seriesOf }}</a>
            </p>
            <hr>
          </ng-container>
          <ng-container *ngIf="object.firstMetadataValue('dc.relation.number') as relationNumber">
            <p class="metadata-field">
              <span>{{ 'dc.relation.number' | translate }}</span>
              {{ relationNumber }}
            </p>
            <hr>
          </ng-container>
          <ng-container *ngIf="object.firstMetadataValue('fao.edition') as faoEdition">
            <p class="metadata-field">
              <span>{{ 'fao.edition' | translate }}</span>
              {{ faoEdition }}
            </p>
            <hr>
          </ng-container>
          <ng-container *ngIf="object.firstMetadataValue('dc.relation.isreplacedby') as replacedBy">
            <p class="metadata-field">
              <span>{{ 'dc.relation.isreplacedby' | translate }}</span>
              {{ replacedBy }}
            </p>
            <hr>
          </ng-container>

          <!--
          <ng-container *ngIf="object.firstMetadataValue('dc.identifier.eisbn') as eisbnIdentifier">
            <p class="metadata-field">
              <span>{{ 'dc.identifier.eisbn' | translate }}</span>
              {{ eisbnIdentifier }}
            </p>
            <hr>
          </ng-container>
          <ng-container *ngIf="object.firstMetadataValue('dc.format.numberofpages') as numberOfPages">
            <p class="metadata-field">
              <span>{{ 'dc.format.numberofpages' | translate }}</span>
              {{ numberOfPages }}
            </p>
            <hr>
          </ng-container>
          <ng-container *ngIf="object.firstMetadataValue('fao.placeofpublication') as faoPlaceOfPub">
            <p class="metadata-field">
              <span>{{ 'fao.placeofpublication' | translate }}</span>
              {{ faoPlaceOfPub }}
            </p>
            <hr>
          </ng-container>
          <ng-container *ngIf="object.firstMetadataValue('dc.topic') as topic">
            <p class="metadata-field">
              <span>{{ 'dc.topic' | translate }}</span>
              {{ topic }}
            </p>
            <hr>
          </ng-container>
          -->

          <div>
            <a class="btn btn-outline-primary" role="button" [routerLink]="[itemPageRoute + '/full']">
              <i class="fas fa-info-circle"></i> {{"item.page.link.full" | translate}}
            </a>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-7 d-flex flex-column">
          <ng-container *ngIf="dcIdentifierUri">
            <p class="metadata-field">
              <span>{{ 'item.page.uri' | translate }}</span>
              <a href="{{ dcIdentifierUri }}">{{ dcIdentifierUri }}</a>
            </p>
            <hr>
          </ng-container>
          <ng-container *ngIf="object.firstMetadataValue('dc.description.abstract') as abstractDesc">
            <p class="metadata-field">
              <span>{{ 'dc.description.abstract' | translate }}</span>
              <span [innerHTML]="abstractDesc"></span>
            </p>
            <hr>
          </ng-container>
          <ng-container *ngIf="object.firstMetadataValue('fao.previewpage') as faoPreviewPage">
            <p class="metadata-field">
              <span [innerHTML]="faoPreviewPage"></span>
            </p>
            <hr>
          </ng-container>
          <ng-container *ngIf="object.firstMetadataValue('fao.meetingtitle') as faoMeetingTitle">
            <p class="metadata-field">
              <span>{{ 'fao.meetingtitle' | translate }}</span>
              <span [innerHTML]="faoMeetingTitle"></span>
            </p>
            <hr>
          </ng-container>
          <ng-container *ngIf="object.firstMetadataValue('fao.meetingsymbol') as faoMeetingSymbol">
            <p class="metadata-field">
              <span>{{ 'fao.meetingsymbol' | translate }}</span>
              <span [innerHTML]="faoMeetingSymbol"></span>
            </p>
            <hr>
          </ng-container>
          <ng-container *ngIf="object.firstMetadataValue('fao.meetingsessionnumber') as faoMeetingSessionNumber">
            <p class="metadata-field">
              <span>{{ 'fao.meetingsessionnumber' | translate }}</span>
              <span [innerHTML]="faoMeetingSessionNumber"></span>
            </p>
            <hr>
          </ng-container>
          <ng-container *ngIf="object.firstMetadataValue('fao.meetingdate') as faoMeetingData">
            <p class="metadata-field">
              <span>{{ 'fao.meetingdate' | translate }}</span>
              <span [innerHTML]="faoMeetingData"></span>
            </p>
            <hr>
          </ng-container>
          <ng-container *ngIf="object.firstMetadataValue('fao.meetinglocation') as faoMeetingLocation">
            <p class="metadata-field">
              <span>{{ 'fao.meetinglocation' | translate }}</span>
              <span [innerHTML]="faoMeetingLocation"></span>
            </p>
            <hr>
          </ng-container>
          <ng-container *ngIf="agrovocValues.length > 0">
            <p class="metadata-field">
              <span>{{ 'fao.subject.agrovoc' | translate }}</span>
              <ng-container *ngFor="let agrovocValue of agrovocValues; let last=last;" class="dont-break-out">
                <a [queryParams]="{'f.agrovoc': agrovocValue.filter, 'spc.page': '1'}" [routerLink]="'/search'">{{agrovocValue.value}}</a><ng-container *ngIf="!last">,</ng-container>
              </ng-container>
            </p>
            <hr>
          </ng-container>
        </div>
        <div class="col-12 col-md-3 col-lg-3 d-flex flex-column">
          <ng-container *ngIf="object.firstMetadataValue('fao.citation') as faoCitation">
            <p class="metadata-field">
              <span>{{ 'fao.citation' | translate }}</span>
              <span [innerHTML]="faoCitation"></span>
            </p>
          </ng-container>
          <ng-container *ngIf="object.firstMetadataValue('fao.identifier.doi') as faoIdentifierDoi">
            <p class="metadata-field">
              <a href="{{faoIdentifierDoi}}">{{ faoIdentifierDoi }}</a>
            </p>
            <hr>
          </ng-container>
          <ng-container *ngIf="object.firstMetadataValue('fao.identifier.googlebookurl') as googleBookUrlIdentifier">
            <p class="metadata-field">
              <a href="{{googleBookUrlIdentifier}}">{{ 'fao.identifier.googlebookurl.value' | translate }}</a>
            </p>
            <hr>
          </ng-container>
          <ng-container *ngIf="object.firstMetadataValue('dc.language.iso') as languageIso">
            <p class="metadata-field">
              <span>{{ 'dc.language.iso' | translate }}</span>
              {{ languageIso }}
            </p>
            <hr>
            <ds-language-availability [item]="object">
            </ds-language-availability>
          </ng-container>

          <!--
          <ng-container *ngIf="object.firstMetadataValue('fao.meetingurl') as faoMeetingUrl">
            <p class="metadata-field">
              <span>{{ 'fao.meetingurl' | translate }}</span>
              {{ faoMeetingUrl }}
            </p>
            <hr>
          </ng-container>
          <ng-container *ngIf="object.firstMetadataValue('fao.statutorybody') as faoStatutorybody">
            <p class="metadata-field">
              <span>{{ 'fao.statutorybody' | translate }}</span>
              {{ faoStatutorybody }}
            </p>
            <hr>
          </ng-container>
          <p class="citation p-3 rounded text-break"
             *ngIf="object.firstMetadataValue('dc.identifier.citation') as citation">
            <span>{{ 'item.page.citation' | translate }}:</span>
            <span [innerHTML]="citation"></span>
          </p>
          -->

          <ds-altmetric-donut [doiIdentifierMdFields]="['fao.identifier.doi']" [item]="object" [smallView]="false"
                              *ngIf="showAltmetricBadge" class="mb-4">
          </ds-altmetric-donut>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-12">
          <ds-statistically-related-items [item]="object"></ds-statistically-related-items>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-12">
          <ds-atmire-cua-statlets-section [dso]="object"></ds-atmire-cua-statlets-section>
        </div>
      </div>
    </div>
  </div>
