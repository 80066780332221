<!--
  The contents of this file are subject to the license and copyright
  detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
  tree and available online at

  https://www.atmire.com/software-license/
 -->
<ng-container *ngVar="(objects$ | async) as objects">
  <h3 [ngClass]="{'sr-only': parentname }" >{{title | translate}}</h3>
  <ng-container *ngComponentOutlet="getStartsWithComponent(); injector: objectInjector;"></ng-container>
  <div *ngIf="objects?.hasSucceeded && !objects?.isLoading && objects?.payload?.page.length > 0" @fadeIn>
    <div *ngIf="!enableArrows">
      <ds-viewable-collection
        [config]="paginationConfig"
        [sortConfig]="sortConfig"
        [objects]="objects">
      </ds-viewable-collection>
    </div>
    <div *ngIf="enableArrows">
      <div class="row">
        <div class="col-12">
          <!-- Atmire modifications START -->
          <ds-client-rss *ngIf="showRss"></ds-client-rss>
          <!-- Atmire modifications END -->
          <div *ngIf="shouldDisplayResetButton$ |async" class="d-inline-block float-left reset">
            <a [queryParams]="{startsWith: null, value: null}" [routerLink]="[]"
               class="btn btn-secondary" queryParamsHandling="merge"><i class="fas fa-arrow-left"></i> {{'browse.back.all-results' | translate}}</a>
          </div>
          <div #paginationControls="ngbDropdown" *ngIf="!hideGear" class="d-inline-block float-right" ngbDropdown placement="bottom-right">
            <button [attr.aria-label]="'pagination.options.description' | translate" [title]="'pagination.options.description' | translate" class="btn btn-secondary" id="paginationControls" ngbDropdownToggle><i aria-hidden="true" class="fas fa-cog"></i></button>
            <div aria-labelledby="paginationControls" id="paginationControlsDropdownMenu" ngbDropdownMenu>
              <h6 class="dropdown-header">{{ 'pagination.results-per-page' | translate}}</h6>
              <button (click)="doPageSizeChange(item)" *ngFor="let item of paginationConfig?.pageSizeOptions" class="dropdown-item page-size-change"><i [ngClass]="{'invisible': item != paginationConfig?.pageSize}" aria-hidden="true" class="fas fa-check"></i> {{item}} </button>
              <h6 class="dropdown-header">{{ 'pagination.sort-direction' | translate}}</h6>
              <button (click)="doSortDirectionChange(direction.value)" *ngFor="let direction of (sortDirections | dsKeys)" class="dropdown-item sort-direction-change"><i [ngClass]="{'invisible': direction.value !== sortConfig?.direction}" aria-hidden="true" class="fas fa-check"></i> {{'sorting.' + direction.key | translate}} </button>
            </div>
          </div>
        </div>
      </div>
      <ul class="list-unstyled">
        <li *ngFor="let object of objects?.payload?.page" class="mt-4 mb-4">
          <ds-listable-object-component-loader [object]="object" [viewMode]="viewMode"></ds-listable-object-component-loader>
        </li>
      </ul>
      <div>
        <button (click)="goPrev()" [disabled]="objects?.payload?.currentPage <= 1" class="btn btn-outline-primary float-left" id="nav-prev"  type="button"><i class="fas fa-angle-left"></i> {{'browse.previous.button' |translate}}</button>
        <button (click)="goNext()" [disabled]="objects?.payload?.currentPage >= objects?.payload?.totalPages" class="btn btn-outline-primary float-right" id="nav-next"  type="button">
                    <span [ngbTooltip]="objects?.payload?.currentPage >= objects?.payload?.totalPages ? getTranslation('browse.next.button.disabled.tooltip') : null">
            <i class="fas fa-angle-right"></i> {{'browse.next.button' |translate}}
                    </span>
        </button>
      </div>
    </div>
  </div>
  <ds-loading *ngIf="!objects || objects?.isLoading" message="{{'loading.browse-by' | translate}}"></ds-loading>
  <ds-error *ngIf="objects?.hasFailed" message="{{'error.browse-by' | translate}}"></ds-error>
  <div *ngIf="!objects?.isLoading && objects?.payload?.page.length === 0">
    <div *ngIf="shouldDisplayResetButton$ |async" class="d-inline-block mb-4 reset">
      <button class="btn btn-secondary" (click)="back()"><i class="fas fa-arrow-left"></i> {{'browse.back.all-results' | translate}}</button>
    </div>
    <div class="alert alert-info w-100" role="alert">
      {{'browse.empty' | translate}}
    </div>
  </div>
</ng-container>
