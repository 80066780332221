/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../app/shared/shared.module';
import { AtmireAppRoutingModule } from './atmire-app-routing.module';
import { DSOEditMenuResolver } from '../app/shared/dso-page/dso-edit-menu.resolver';
import { AtmireDsoEditMenuResolver } from './shared/dso-page/atmire-dso-edit-menu.resolver';
import { SectionHeaderMenuItemComponent } from './shared/menu/menu-item/section-header-menu-item.component';
import { ThemeService } from '../app/shared/theme-support/theme.service';
import { AtmireThemeService } from './shared/theming-support/atmire-theme.service';
import { AtmireThemedRootComponent } from './root/atmire-themed-root.component';
import { AtmireSelectiveExportSharedModule } from './atmire-selective-export/atmire-selective-export-shared/atmire-selective-export-shared.module';
import { CmsMenuGuard } from './cms-menu/cms-menu-guard';
import { SuggestionDataService } from './core/data/suggestion-data.service';
import { SearchInputFieldComponent } from './shared/search-form/search-input-field/search-input-field.component';
import { JournalSuggestionPreviewComponent } from './shared/search-form/search-input-field/suggestions/suggestion-preview/object-suggestion-preview/item-suggestion-preview/entity-types/journal-suggestion-preview/journal-suggestion-preview.component';
import { SuggestionResultWrapperComponent } from './shared/search-form/search-input-field/suggestions/suggestion-result/suggestion-result-wrapper.component';
import { FilterSuggestionResultComponent } from './shared/search-form/search-input-field/suggestions/suggestion-result/filter-suggestion-result.component/filter-suggestion-result.component';
import { SearchSuggestionResultComponent } from './shared/search-form/search-input-field/suggestions/suggestion-result/search-suggestion-result.component/search-suggestion-result.component';
import { ObjectSuggestionResultComponent } from './shared/search-form/search-input-field/suggestions/suggestion-result/object-suggestion-result.component/object-suggestion-result.component';
import { SearchSuggestionPreviewComponent } from './shared/search-form/search-input-field/suggestions/suggestion-preview/search-suggestion-preview/search-suggestion-preview.component';
import { FilterSuggestionPreviewComponent } from './shared/search-form/search-input-field/suggestions/suggestion-preview/filter-suggestion-preview/filter-suggestion-preview.component';
import { ObjectSuggestionPreviewComponent } from './shared/search-form/search-input-field/suggestions/suggestion-preview/object-suggestion-preview/object-suggestion-preview.component';
import { CommunitySuggestionPreviewComponent } from './shared/search-form/search-input-field/suggestions/suggestion-preview/object-suggestion-preview/community-suggestion-preview/community-suggestion-preview.component';
import { CollectionSuggestionPreviewComponent } from './shared/search-form/search-input-field/suggestions/suggestion-preview/object-suggestion-preview/collection-suggestion-preview/collection-suggestion-preview.component';
import { PersonSuggestionPreviewComponent } from './shared/search-form/search-input-field/suggestions/suggestion-preview/object-suggestion-preview/item-suggestion-preview/entity-types/person-suggestion-preview/person-suggestion-preview.component';
import { ItemSuggestionPreviewComponent } from './shared/search-form/search-input-field/suggestions/suggestion-preview/object-suggestion-preview/item-suggestion-preview/item-suggestion-preview.component';
import { SuggestionPreviewWrapperComponent } from './shared/search-form/search-input-field/suggestions/suggestion-preview/suggestion-preview-wrapper.component';
import { SuggestionResultComponent } from './shared/search-form/search-input-field/suggestions/suggestion-result/suggestion-result.component';
import { SuggestionPreviewComponent } from './shared/search-form/search-input-field/suggestions/suggestion-preview/suggestion-preview.component';
import { SuggestionResultDirective } from './shared/search-form/search-input-field/suggestions/suggestion-result/suggestion-result.directive';
import { SuggestionPreviewDirective } from './shared/search-form/search-input-field/suggestions/suggestion-preview/suggestion-preview.directive';
import { AtmireSearchService } from './core/shared/search/atmire-search.service';
import { AtmireSearchNavbarComponent } from './search-navbar/atmire-search-navbar.component';
import { AtmireLinkMenuItemComponent } from './shared/menu/menu-item/atmire-link-menu-item.component';
import { MultilingualMetadataService } from './core/shared/multilingual-metadata.service';
import { AtmireComColFormComponent } from './shared/comcol/comcol-forms/comcol-form/atmire-comcol-form.component';
import { FormModule } from '../app/shared/form/form.module';
import { ComcolModule } from '../app/shared/comcol/comcol.module';
import { ComcolLanguagesComponent } from './shared/comcol/comcol-forms/edit-comcol-page/comcol-languages/comcol-languages.component';
import { CollectionLanguagesComponent } from './collection-page/edit-collection-page/collection-languages/collection-languages.component';
import { CommunityLanguagesComponent } from './community-page/edit-community-page/community-languages/community-languages.component';
import { CommunityFormModule } from '../app/community-page/community-form/community-form.module';
import { CollectionFormModule } from '../app/collection-page/collection-form/collection-form.module';
import { AtmireAbstractListableElementComponent } from './shared/object-collection/shared/object-collection-element/atmire-abstract-listable-element.component';
import { AtmireSearchResultListElementComponent } from './shared/object-list/search-result-list-element/atmire-search-result-list-element.component';
import { AtmireSearchResultDetailElementComponent } from './shared/object-detail/my-dspace-result-detail-element/atmire-search-result-detail-element.component';
import { AtmireSearchResultGridElementComponent } from './shared/object-grid/search-result-grid-element/atmire-search-result-grid-element.component';
import { AtmireSidebarSearchListElementComponent } from './shared/object-list/sidebar-search-list-element/atmire-sidebar-search-list-element.component';
import { MultilingualComcolPageContentComponent } from './shared/comcol/comcol-page-content/multilingual-comcol-page-content.component';
import { MultilingualComcolPageHeaderComponent } from './shared/comcol/comcol-page-header/multilingual-comcol-page-header.component';
import { MultilingualCollectionsComponent } from './item-page/field-components/multilingual-collections/multilingual-collections.component';
import { AtmireCreateComColPageComponent } from './shared/comcol/comcol-forms/create-comcol-page/atmire-create-comcol-page.component';
import { AtmireCoreModule } from './core/atmire-core.module';
import { DsoAddUuidButtonComponent } from './dso-page/dso-add-uuid-button/dso-add-uuid-button.component';
import { LangSwitchService } from './shared/lang-switch/lang-switch.service';
import { SuggestResponseParsingService } from './core/data/suggest-response-parsing.service';
import { SocialSharingModule } from './social-sharing/social-sharing.module';
import { AtmireRelatedItemsModule } from './atmire-related-items/atmire-related-items.module';
import { AtmireItemPageResolver } from './item-page/atmire-item-page.resolver';
import { DSONameService } from '../app/core/breadcrumbs/dso-name.service';
import { AtmireDSONameService } from './core/breadcrumbs/atmire-dso-name.service';
import { LinkService } from '../app/core/cache/builders/link.service';
import { AtmireSavedItemListDataService } from './atmire-saved-item-list/data-services/atmire-saved-item-list-data.service';
import { AtmireItemDataService } from './core/data/atmire-item-data.service';
import { AtmireSavedItemListStoreService } from './atmire-saved-item-list/store/atmire-saved-item-list-store.service';
import { AtmireAuthService } from './core/auth/atmire-auth.service';
import { AtmireLinkService } from './core/cache/builders/atmire-link.service';
import { SearchTermsDataService } from './atmire-cua/search-reports/search-reports-service/search-terms-data.service';
import { AtmireSavedItemListFeatureAuthorizationGuard } from './atmire-saved-item-list/atmire-saved-item-list-page/atmire-saved-item-list-feature-authorization.guard';
import { AtmireValuePairDataService } from './atmire-cua/shared-reports/value-pairs/atmire-value-pair-data.service';
import { SearchReportsDataService } from './atmire-cua/search-reports/search-reports-service/search-reports-data.service';
import { AtmireSharedModule } from './shared/atmire-shared.module';
import { AtmireObjectCollectionModule } from './atmire-object-collection/atmire-object-collection.module';
import { SharedStatletsModule } from './atmire-cua/statlets/shared/shared-statlets.module';
import { AtmireFeaturedItemsSharedModule } from './atmire-featured-items/atmire-featured-items-shared.module';

const DECLARATIONS = [
  AtmireThemedRootComponent,
  SectionHeaderMenuItemComponent,
  SearchInputFieldComponent,
  SuggestionPreviewWrapperComponent,
  ItemSuggestionPreviewComponent,
  JournalSuggestionPreviewComponent,
  PersonSuggestionPreviewComponent,
  CollectionSuggestionPreviewComponent,
  CommunitySuggestionPreviewComponent,
  ObjectSuggestionPreviewComponent,
  FilterSuggestionPreviewComponent,
  SearchSuggestionPreviewComponent,
  SuggestionResultWrapperComponent,
  ObjectSuggestionResultComponent,
  SearchSuggestionResultComponent,
  FilterSuggestionResultComponent,
  SuggestionResultComponent,
  SuggestionPreviewComponent,
  AtmireSearchNavbarComponent,
  AtmireLinkMenuItemComponent,
  DsoAddUuidButtonComponent,
  AtmireAbstractListableElementComponent,
  AtmireComColFormComponent,
  AtmireCreateComColPageComponent,
  AtmireSearchResultDetailElementComponent,
  AtmireSearchResultGridElementComponent,
  AtmireSearchResultListElementComponent,
  AtmireSidebarSearchListElementComponent,
  ComcolLanguagesComponent,
  CollectionLanguagesComponent,
  CommunityLanguagesComponent,
  MultilingualCollectionsComponent,
  MultilingualComcolPageContentComponent,
  MultilingualComcolPageHeaderComponent,
];

/**
 * Add components that use a custom decorator to ENTRY_COMPONENTS as well as DECLARATIONS. This will
 * ensure that decorator gets picked up when the app loads
 */
const ENTRY_COMPONENTS = [
  SectionHeaderMenuItemComponent,
  ItemSuggestionPreviewComponent,
  JournalSuggestionPreviewComponent,
  PersonSuggestionPreviewComponent,
  CollectionSuggestionPreviewComponent,
  CommunitySuggestionPreviewComponent,
  ObjectSuggestionPreviewComponent,
  FilterSuggestionPreviewComponent,
  SearchSuggestionPreviewComponent,
  ObjectSuggestionResultComponent,
  SearchSuggestionResultComponent,
  FilterSuggestionResultComponent,
  AtmireLinkMenuItemComponent
];

const PROVIDERS = [
  {
    provide: DSOEditMenuResolver,
    useClass: AtmireDsoEditMenuResolver,
  },
  { provide: DSONameService, useClass: AtmireDSONameService },
  { provide: ThemeService, useClass: AtmireThemeService },
  { provide: LinkService, useClass: AtmireLinkService },
  AtmireSavedItemListStoreService,
  AtmireSavedItemListDataService,
  AtmireAuthService,
  AtmireItemDataService,
  AtmireSearchService,
  SuggestResponseParsingService,
  SuggestionDataService,
  MultilingualMetadataService,
  LangSwitchService,
  AtmireItemPageResolver,
  SearchTermsDataService,
  SearchReportsDataService,
  AtmireValuePairDataService,
  AtmireSavedItemListFeatureAuthorizationGuard,
  MultilingualMetadataService,
  CmsMenuGuard,
];

const DIRECTIVES = [
  SuggestionResultDirective,
  SuggestionPreviewDirective,
];

@NgModule({
  declarations: [
    ...DECLARATIONS,
    ...DIRECTIVES,
  ],
  providers: [
    ...PROVIDERS,
    ...ENTRY_COMPONENTS.map((component) => ({ provide: component }))
  ],
  imports: [
    CollectionFormModule,
    CommunityFormModule,
    ComcolModule,
    CommonModule,
    FormModule,
    SharedModule,
    AtmireSharedModule,
    SharedStatletsModule,
    AtmireAppRoutingModule,
    AtmireObjectCollectionModule,
    AtmireSelectiveExportSharedModule,
    AtmireCoreModule,
    AtmireFeaturedItemsSharedModule,
    SocialSharingModule,
    AtmireRelatedItemsModule,
  ],
  exports: [
    ...DECLARATIONS,
    ...DIRECTIVES,
  ]
})
export class AtmireAppModule {

}
