/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AtmireItemPageResolver } from './atmire-item-page.resolver';
import { AuthenticatedGuard } from '../../app/core/auth/authenticated.guard';
import { ItemBreadcrumbResolver } from '../../app/core/breadcrumbs/item-breadcrumb.resolver';
import { UploadBitstreamComponent } from '../../app/item-page/bitstreams/upload/upload-bitstream.component';
import { ITEM_EDIT_PATH, UPLOAD_BITSTREAM_PATH } from '../../app/item-page/item-page-routing-paths';
import { ThemedItemPageComponent } from '../../app/item-page/simple/themed-item-page.component';
import { ThemedFullItemPageComponent } from '../../app/item-page/full/themed-full-item-page.component';
import {
  BitstreamRequestACopyPageComponent
} from '../../app/shared/bitstream-request-a-copy-page/bitstream-request-a-copy-page.component';
import { REQUEST_COPY_MODULE_PATH } from '../../app/app-routing-paths';
import { MenuItemType } from '../../app/shared/menu/initial-menus-state';
import { LinkMenuItemModel } from '../../app/shared/menu/menu-item/models/link.model';
import { ViewDsoPageGuard } from '../../app-client/view-dso-page.guard';
import { DSOEditMenuResolver } from '../../app/shared/dso-page/dso-edit-menu.resolver';

const routes: Routes = [
  {
    path: ':id',
        canActivate: [ViewDsoPageGuard],
    resolve: {
      dso: AtmireItemPageResolver,
      breadcrumb: ItemBreadcrumbResolver,
      menu: DSOEditMenuResolver
    },
    runGuardsAndResolvers: 'always',
    children: [
      {
        path: '',
        component: ThemedItemPageComponent,
        pathMatch: 'full',
      },
      {
        path: 'full',
        component: ThemedFullItemPageComponent,
      },
      {
        path: ITEM_EDIT_PATH,
        loadChildren: () => import('../../app/../app/item-page/edit-item-page/edit-item-page.module')
          .then((m) => m.EditItemPageModule),
      },
      {
        path: UPLOAD_BITSTREAM_PATH,
        component: UploadBitstreamComponent,
        canActivate: [AuthenticatedGuard]
      },
      {
        path: REQUEST_COPY_MODULE_PATH,
        component: BitstreamRequestACopyPageComponent,
      },
      {
        path: ':dsoId/pages',
        loadChildren: './../static-page/static-page.module#StaticPageModule'
      }
    ],
    data: {
      menu: {
        public: [],
      },
    },
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
  ],
  providers: [
    AtmireItemPageResolver,
    ItemBreadcrumbResolver,
  ],
  exports: [RouterModule]
})
export class AtmireItemPageRoutingModule {
}
