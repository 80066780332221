<!--
  The contents of this file are subject to the license and copyright
  detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
  tree and available online at

  https://www.atmire.com/software-license/
 -->
<svg>
  <rect
    *ngFor="let c of emptySlots; trackBy: trackBy"
    class="card-empty"
    [attr.x]="c.x"
    [attr.y]="c.y"
    [style.fill]="emptyColor"
    [attr.width]="c.width"
    [attr.height]="c.height"
    rx="3"
    ry="3"
  ></rect>
  <g *ngFor="let c of cards; trackBy: trackBy">
    <g [attr.transform]="'translate(' + c.x + ',' + c.y + ')'" class="cell" (click)="onClick(c.data)">
      <rect class="card" [style.fill]="c.color" [attr.width]="c.width" [attr.height]="c.height" rx="3" ry="3" />
      <text
        class="number-label"
        text-anchor="start"
        dominant-baseline="hanging"
        [style.font-size.pt]="10"
      >
        {{ c.label }}
      </text>
      <text
        #textEl
        class="number-value"
        [attr.x]="cardX"
        [attr.y]="cardY"
        [style.fill]="textColor"
        text-anchor="start"
        dominant-baseline="hanging"
        [style.font-size.pt]="txtFontSize"
      >
        {{ c.data.value | dsDecimal:'1.0-2' }}
      </text>
    </g>
  </g>
</svg>
