/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Component, OnDestroy } from '@angular/core';
import { SingleStatletWrapperComponent } from '../single-statlet-wrapper.component';
import { DEFAULT_STATLET_WRAPPER_CONTEXT, rendersSingleStatletWrapper } from '../single-statlet-wrapper.decorator';
import { saveAs } from 'file-saver';
import { StatletDataService } from '../../data/services/statlet-data.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'ds-atmire-cua-single-statlet-card',
  templateUrl: './single-statlet-card.component.html',
  styleUrls: ['./single-statlet-card.component.scss']
})
/**
 * Component displaying a single statlet in a card
 * This component displays a card containing an icon depending on the graph-type of the statlet and loads the correct
 * statlet component within the card's body depending on the statlet's graph-type
 */
@rendersSingleStatletWrapper(DEFAULT_STATLET_WRAPPER_CONTEXT)
export class SingleStatletCardComponent extends SingleStatletWrapperComponent implements OnDestroy {
  showListView = false;

  subs: Subscription[] = [];

  constructor(
    protected statletService: StatletDataService,
  ) {
    super();
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub: Subscription) => sub.unsubscribe());
  }

  /**
   * Downloads the current {@link Statlet} data as a CSV file
   */
  downloadCSV(): void {
    this.subs.push(this.statletService.exportAsCSV(this.statlet.id).subscribe((data: Blob) => {
      saveAs(data, `${this.statlet.shortName}.csv`);
    }));
  }
}
