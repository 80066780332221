/**
 * The contents of this file are subject to the license and copyright
 * detailed in the LICENSE_ATMIRE and NOTICE_ATMIRE files at the root of the source
 * tree and available online at
 *
 * https://www.atmire.com/software-license/
 */
import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable, of as observableOf } from 'rxjs';
import { filter, map, switchMap, take } from 'rxjs/operators';
import { LinkService } from 'src/app/core/cache/builders/link.service';
import { DSpaceObjectDataService } from 'src/app/core/data/dspace-object-data.service';
import { RemoteData } from 'src/app/core/data/remote-data';
import { LocaleService } from 'src/app/core/locale/locale.service';
import { DSpaceObject } from 'src/app/core/shared/dspace-object.model';
import { getFirstSucceededRemoteData, getRemoteDataPayload } from 'src/app/core/shared/operators';
import { hasValue, isNotEmpty, hasNoValue } from 'src/app/shared/empty.util';
import { NO_OP_ACTION_TYPE, NoOpAction } from 'src/app/shared/ngrx/no-op.action';
import {
  GET_THEME_CONFIG_FOR_FACTORY
} from 'src/app/shared/object-collection/shared/listable-object/listable-object.decorator';
import { ThemeState } from 'src/app/shared/theme-support/theme.reducer';
import { currentThemeSelector, ThemeService } from 'src/app/shared/theme-support/theme.service';
import { Theme } from '../../../config/theme.model';
import { ThemeActionTypes } from '../../../app/shared/theme-support/theme.actions';
import { AtmireTheme, AtmireThemeConfig, atmireThemeFactory } from '../../config/atmire-theme.model';
import { environment } from '../../../environments/environment';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class AtmireThemeService extends ThemeService {
  /**
   * The list of configured themes
   */
  themes: Theme[];

  /**
   * True if at least one theme depends on the route
   */
  hasDynamicTheme: boolean;

  constructor(
    protected store: Store<ThemeState>,
    protected linkService: LinkService,
    protected dSpaceObjectDataService: DSpaceObjectDataService,
    @Inject(GET_THEME_CONFIG_FOR_FACTORY) protected gtcf: (str) => AtmireThemeConfig,
    protected router: Router,
    @Inject(DOCUMENT) protected document: any,
    protected localeService: LocaleService,
  ) {
    super(store, linkService, dSpaceObjectDataService, gtcf, router, document);
    // Create objects from the theme configs in the environment file
    this.themes = environment.themes.map((themeConfig: AtmireThemeConfig) => atmireThemeFactory(themeConfig));
    this.hasDynamicTheme = environment.themes.some((themeConfig: any) =>
      hasValue(themeConfig.regex) ||
      hasValue(themeConfig.handle) ||
      hasValue(themeConfig.uuid) ||
      themeConfig.isRTL
    );
  }

  protected loadGlobalThemeConfig(themeName: string): void {
    super.loadGlobalThemeConfig(themeName);
    this.setRtlTags(themeName);
  }

  private setRtlTags(themeName: string): void {
    const htmlTag = this.document.documentElement;
    if (hasNoValue(htmlTag)) {
      return;
    }

    const themeConfig = this.getThemeConfigFor(themeName);
    if (themeConfig.isRTL) {
      htmlTag.setAttribute('dir', 'rtl');
    } else {
      htmlTag.removeAttribute('dir');
    }
  }

  /**
   * Determine whether or not the theme needs to change depending on the current route's URL and snapshot data
   * If the snapshot contains a dso, this will be used to match a theme
   * If the snapshot contains a scope parameters, this will be used to match a theme
   * Otherwise the URL is matched against
   * If none of the above find a match, the theme doesn't change
   * @param currentRouteUrl
   * @param activatedRouteSnapshot
   * @return Observable boolean emitting whether or not the theme has been changed
   */
  updateThemeOnRouteChange$(currentRouteUrl: string, activatedRouteSnapshot: ActivatedRouteSnapshot): Observable<boolean> {
    // and the current theme from the store
    const currentTheme$: Observable<string> = this.store.pipe(select(currentThemeSelector));

    const action$ = currentTheme$.pipe(
      switchMap((currentTheme: string) => {
        const snapshotWithData = this.findRouteData(activatedRouteSnapshot);
        if (this.hasDynamicTheme === true && isNotEmpty(this.themes)) {
          if (hasValue(snapshotWithData) && hasValue(snapshotWithData.data) && hasValue(snapshotWithData.data.dso)) {
            const dsoRD: RemoteData<DSpaceObject> = snapshotWithData.data.dso;
            if (dsoRD.hasSucceeded) {
              // Start with the resolved dso and go recursively through its parents until you reach the top-level community
              return observableOf(dsoRD.payload).pipe(
                this.getAncestorDSOs(),
                map((dsos: DSpaceObject[]) => {
                  const dsoMatch = this.matchThemeToDSOs(dsos, currentRouteUrl);
                  return this.getActionForMatch(dsoMatch, currentTheme);
                })
              );
            }
          }
          if (hasValue(activatedRouteSnapshot.queryParams) && hasValue(activatedRouteSnapshot.queryParams.scope)) {
            const dsoFromScope$: Observable<RemoteData<DSpaceObject>> = this.dSpaceObjectDataService.findById(activatedRouteSnapshot.queryParams.scope);
            // Start with the resolved dso and go recursively through its parents until you reach the top-level community
            return dsoFromScope$.pipe(
              getFirstSucceededRemoteData(),
              getRemoteDataPayload(),
              this.getAncestorDSOs(),
              map((dsos: DSpaceObject[]) => {
                const dsoMatch = this.matchThemeToDSOs(dsos, currentRouteUrl);
                return this.getActionForMatch(dsoMatch, currentTheme);
              })
            );
          }


          const langConfig = environment.languages.find((MyLangConfig) => MyLangConfig.code === this.localeService.getCurrentLanguageCode());
          // check whether the route itself matches
          const routeMatch = this.themes.find((theme: AtmireTheme) => theme.matches(currentRouteUrl, undefined, langConfig.isRTL));

          return [this.getActionForMatch(routeMatch, currentTheme)];
        }

        // If there are no themes configured, do nothing
        return [new NoOpAction()];
      }),
      take(1),
    );

    action$.pipe(
      filter((action) => action.type !== NO_OP_ACTION_TYPE),
    ).subscribe((action) => {
      this.store.dispatch(action);
    });

    return action$.pipe(
      map((action) => action.type === ThemeActionTypes.SET),
    );
  }

  /**
   * Check the given DSpaceObjects in order to see if they match the configured themes in order.
   * If a match is found, the matching theme is returned
   *
   * @param dsos The DSpaceObjects to check
   * @param currentRouteUrl The url for the current route
   * @private
   */
  protected matchThemeToDSOs(dsos: DSpaceObject[], currentRouteUrl: string): AtmireTheme {
    // iterate over the themes in order, and return the first one that matches
    return this.themes.find((theme: AtmireTheme) => {
      // iterate over the dsos's in order (most specific one first, so Item, Collection,
      // Community), and return the first one that matches the current theme
      const langConfig = environment.languages.find((MyLangConfig) => MyLangConfig.code === this.localeService.getCurrentLanguageCode());
      const match = dsos.find((dso: DSpaceObject) => theme.matches(currentRouteUrl, dso, langConfig.isRTL));
      return hasValue(match);

    });
  }

  /**
   * Searches for a ThemeConfig by its name;
   */
  getThemeConfigFor(themeName: string): AtmireThemeConfig {
    return this.gtcf(themeName);
  }

}
